.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar {
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: space-between;
  border: 1px  solid rgb(192, 190, 190);
  z-index: 100;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 60px);
  padding: 14px 30px;
}

.navbar-logo{
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 20%;
}

.navbar-logo-image {
  height: 50px;
  width: auto;
  border-radius: 10px;
  margin-right: 15px;
  transition: transform 0.3s ease;
}


.navbar-logo-image:hover {
  transform: scale(1.1);
}
.navbar-search-image {
  height: 30px;
  width: auto;
}

.navbar-company-info {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.navbar-company-info-time {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  opacity: 0.6;
}

.navbar-company-info-branch-sum{
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 1rem;
}

.navbar-title-parent {
  width: fit-content;
}
.navbar-title {
  width: fit-content;
  margin: 0;
  font-size: 2.2rem;
  text-align: left;
}

.navbar-search {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-grow: 2;
  position: relative;
  max-width: 100%;
  margin: 0 20px;
  gap: 40px;
}

.navbar-search input {
  padding: 10px;
  min-width: 85%;
  max-width: 100%;
  height: 22px;
  margin-left: 10px;
  border: none;
  outline: none;
  font-size: 18px;
}

.navbar-search button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 10px;
  height: 90%;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.navbar-links a {
  margin-left: 20px;
  color: #000;
  text-decoration: none;
}

.navbar-links a:hover {
  text-decoration: underline;
}

form{
  width: 100%;
  border: 1px solid rgb(91, 91, 91);
  background-color: rgba(255, 255, 255);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.link-with-image-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; 
  text-align: center; 
}

.link-with-image-text img {
  height: 30px;
  width: auto;
}

.link-with-image-text-h {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; 
  text-align: center;
  cursor: pointer;
  gap: 9px;
}


.link-with-image-text-h img {
  height: 30px;
  width: auto;
}

.link-with-image-text-h span {
  font-size: 16px;
  font-weight: bold;
}


@media screen and (max-width: 1300px) {

  .navbar-title {
    font-size: 1.5rem;
  }
  .navbar-logo{
    margin-right: 0;
   margin-right: 5%;
   }
}

@media screen and (max-width: 900px) {

.navbar {
  position:fixed;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 10px;
}


  .navbar-title {
    font-size: 1.3rem;
  }
  .navbar-logo{
   margin-right: 0;
  }
  .navbar-logo-image {
    height: 38px;
    width: auto;
    border-radius: 10px;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
    padding: 0 10px;
    margin-top: 7px;
  }

  .link-with-image-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; 
    text-align: center; 
  }
  
  .link-with-image-text img {
    height: 30px;
    width: auto;
  }
  .link-with-image-text span {
    font-size: 13px;
  }

  .link-with-image-text-h {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; 
    text-align: center;
    gap: 6px;
  }
  
  
  .link-with-image-text-h img {
    height: 25px;
    width: auto;
  }
  
  .link-with-image-text-h span {
    font-size: 15px;
    font-weight: bold;
  }
  
}

.popular-categories-bg {
  width: 100%;
  background-color: #F5F5F7;
}

.popular-categories-container {
  padding: 10px;
  padding-top: 80px;
  width: 100;
}

.popular-categories-container {
  margin: 0 20px;
}

.popular-categories-list-container {
  display: grid;
  flex: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  padding: 0 10px;
}

@media screen and (max-width: 1024px) {
  .popular-categories-list-container{
    display: grid;
    flex: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
}
@media screen and (max-width: 768px) {
  .popular-categories-list-container{
    display: grid;
    flex: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .popular-categories-container {
    margin: 0;
  }

  .popular-categories-container h1 {
    margin-left: 20px;
  }
}



.papular-categories-item {
  flex-grow: 1;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-decoration: none;
}
a {
  text-decoration: none;
  color: #000;
}

.papular-categories-item-text {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  text-decoration: none;
}

.papular-categories-item:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.papular-categories-item p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: 000;
}

.papular-categories-item_next {
  width: 30px;
  height: 30px;
}

.papular-categories-item_backgound {
  display: flex;
  justify-content:space-between;
  flex-direction: column;
}

.papular-categories-item_backgound_image {
  max-width: 120px;
  max-height: 120px;
  margin-left: 10px;
}
@media screen and (max-width: 600px) {
  .popular-categories-list-container{
    display: grid;
    flex: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .popular-categories-container h1 {
    font-size: 20px;
  }

  .papular-categories-item {
    flex-grow: 1;
    border-radius: 12px;
    overflow: hidden;
    background-color: #F5F5F7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-decoration: none;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  
  .papular-categories-item-text {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-decoration: none;
  }
  
  .papular-categories-item:hover {
    transform: scale(1.07);
    cursor: pointer;
  }
  
  .papular-categories-item p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: 000;
  }
  
  .papular-categories-item_next {
    width: 20px;
    height: 20px;
  }
  
  .papular-categories-item_backgound {
    display: flex;
    justify-content:space-between;
    flex-direction: column;
  }
  
  .papular-categories-item_backgound_image {
    max-width: 80px;
    max-height: 80px;
    margin-left: 5px;
  }

}


.newest-posts-container {
  width: 100%;
  background-color: #F5F5F7;
}

.newest-posts-body {
  padding: 10px 10px;
}



.newest-posts-parent {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
}

.post-item-container {
  width: 210px;
  max-height: 350px; 
  margin: 10px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #F5F5F7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 20px;
  transition: box-shadow 0.3s ease;
}

.post-item-container:hover {
  box-shadow: 0 3px 6px rgba(39, 40, 44, 0.4);
  cursor: pointer;
}


.post-item-container img {
  width: 100%;
}

.post-item-text-parent {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.post-item-text-parent-name {
  width: 90%;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-size: 18px;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-item-prices-parent {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 10px;
}

.post-item-price {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  color: red;
}

.post-item-price-old {
  padding: 0;
  margin: 0;
  font-size: 17px;
  margin-left: 15px;
  color: #BDBDBD;
  text-decoration: line-through;
}
.newest-posts-body-title {
  margin: 0 20px;
}


@media screen and (max-width: 600px) {
  .newest-posts-body-title {
    font-size: 20px;
  }
  .post-item-container{
    width: 160px;
    max-height: 350px; 
    margin: 7px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #F5F5F7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .post-item-text-parent {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .post-item-text-parent-name {
    width: 90%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    max-lines: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .post-item-prices-parent {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 10px;
  }
  
  .post-item-price {
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    color: red;
  }
  
  .post-item-price-old {
    padding: 0;
    margin: 0;
    font-size: 13px;
    margin-left: 15px;
    color: #BDBDBD;
    text-decoration: line-through;
  }
}


/* ------------< Post Page Design >--------- */

.post-page-container {
  width: 100%;
}

.post-page-body {
  padding: 20px;
  padding-top: 80px;
}

.post-page-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.post-page-top-left-body {
  width: 70%;
  padding: 5px 20px;
  background-color: #F5F5F7;
  border-radius: 16px;
  margin-top: 20px;

}
.post-page-name-post {
  width: 100%;
  font-size: 20px;
  margin: 10px;
  padding: 0;
  font-weight: bold;
}

.post-page-code-product {
  width: 100%;
  padding: 0;
  margin: 10px;
  margin-bottom: 20px;
}

.post-page-top-left-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.post-page-image-container {
  width: 45%;
}

.post-page-image-container img {
  height: 100%;
  width: 100%;
  inset: 0px;
  object-fit: cover;
  object-position: center center;
  color: transparent;
  border-radius: 16px;
}

.post-page-price-pody {
  width: 100%;
  padding: 0 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.post-page-price-pody-top {
  padding: 16px;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 8px;
}

.post-page-price-pody-top-title{
  margin: 10px 0;
  padding: 0;
  font-size: 20px;
}

.post-page-price-pody-parent,
.post-page-price-pody-parent-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.post-page-price {
  font-weight: bold;
  font-size: 20px;
  color: black ;
  margin:  0;
  padding: 0;
}

.post-page-price-old {
  margin:  0;
  padding: 0;
  font-size: 20px;
  color: #BDBDBD;
  text-decoration: line-through;
  margin-left: 20px;
}

.post-page-price-pody-credit-title {
  margin: 10px 0;
  margin-top: 20px;
  padding: 0;
  font-size: 20px;
}

.post-page-credit-info {
  font-weight: bold;
  font-size: 20px;
  color: black ;
  margin:  0;
  padding: 0;
}

.post-page-buy-buttons-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.post-page-buy-to-busket {
  outline: 0;
  border: 0;
  margin: 0;
  width: 45%;
  height: 50px;
  font-weight: bold;
  background-color: #0CD46C;
  box-shadow: none;
  border-radius: 10px;
  font-size: 16px;
  padding: 6px 16px;
  cursor: pointer;
  color: white;
}

.post-page-buy-now {
  outline: 0;
  border: 0;
  margin: 0;
  width: 45%;
  height: 50px;
  font-weight: bold;
  background-color: transparent;
  box-shadow: none;
  border-radius: 10px;
  font-size: 16px;
  padding: 6px 16px;
  cursor: pointer;
  color: #0CD46C;
  border: 1px solid #0CD46C;
}

.post-page-price-pody-bottom{
  margin-top: 10px;
  padding: 16px;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 8px;
}

.post-page-price-pody-bottom-title {
  margin: 10px 0;
  padding: 0;
  font-size: 20px;
  
}

.post-page-price-pody-bottom-buttons-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 0;
}

.post-page-price-pody-bottom-selected {
  outline: 0;
  border: 0;
  margin: 0;
  width: 47%;
  height: 40px;
  font-weight: bold;
  box-shadow: none;
  border-radius: 20px;
  font-size: 16px;
  padding: 6px 16px;
  cursor: pointer;
  color: #0CD46C;
  border: 1px solid #0CD46C;
  background-color: rgba(12, 212, 108, 0.14);
}

.post-page-price-pody-bottom-unselect {
  outline: 0;
  border: 0;
  margin: 0;
  width: 47%;
  height: 40px;
  font-weight: bold;
  background-color: #FBFBFC;
  border-color: rgba(51, 51, 51, 0.07);
  box-shadow: none;
  border-radius: 20px;
  font-size: 16px;
  padding: 6px 16px;
  cursor: not-allowed;
  color: #0CD46C;
  border: 1px solid #0CD46C;
}

.post-page-price-pody-bottom-time-credit {
  outline: 0;
  border: 0;
  margin: 0;
  width: 36%;
  height: 40px;
  font-weight: bold;
  box-shadow: none;
  border-radius: 20px;
  font-size: 16px;
  padding: 6px 16px;
  cursor: pointer;
  color: #0CD46C;
  border: 1px solid #0CD46C;
  background-color: rgba(12, 212, 108, 0.14);
}

.post-page-top-right {
  width: 25%;
  margin-right: 20px;
}

.post-page-top-right-top {
  width: 100%;
  padding: 16px;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 20px;
}

.post-page-top-right-top-title,
.post-page-top-right-bottom-title {
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.post-page-description-text {
  font-family:'Times New Roman', Times, serif;
  margin: 10px 0;
  padding: 0;
  font-size: 18px;
}

.post-page-top-right-bottom {
  width: 100%;
  padding: 16px;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 20px;
}

.post-page-root {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
}


@media screen and (max-width: 800px) {
  .post-page-body {
    padding: 0;
    margin: 0;
    padding: 5px;
    padding-top: 40px;
    margin-top: 5px;
  }
  
  .post-page-top {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .post-page-top-left-body {
    padding: 0;
    margin: 0;
    width: 95%;
    padding: 5px;
    background-color: #F5F5F7;
    border-radius: 14px;
    margin-top: 20px;
  
  }
  .post-page-top-left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .post-page-image-container {
    width: 100%;
  }
  
  .post-page-image-container img {
    height: 100%;
    width: 100%;
    inset: 0px;
    object-fit: cover;
    object-position: center center;
    color: transparent;
    border-radius: 16px;
  }
  .post-page-price-pody {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .post-page-price-pody-top {
    padding: 16px;
    border: 1px solid #efefef;
    background-color: #fff;
    border-radius: 8px;
  }


  .post-page-top-right {
    padding: 0;
    margin: 0;
    width: 90%;
    align-items: center;
  }

  .post-page-top-right-top {
    padding: 0;
    margin: 0;
    width: 100%;
    padding: 16px;
    border: 1px solid #efefef;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;
  }
}


.footer-app-links {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 20px;
}

.footer-app-links img {
  width: 40%;
  transition: transform 0.3s;
}

.footer-app-links img:hover {
  transform: scale(1.1);
}


.search-page-root {
  width: 100%;
  background-color: #f5f5f5;
}

.search-page-body {
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.search-page-body-left {
  width: 28%;
}

.search-page-body-right {
  width: 67%;

}

.search-page-filter-parent {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  padding: 10px 10px;
  margin-top: 20px;
}
.search-page-filter-price-min-parent,
  .search-page-filter-price-max-parent {
  width: 70%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  font-family: Mulish, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    align-items: center;
    border: 1px solid rgb(239, 239, 239);
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
}

.search-page-filter-price-min-parent p,
.search-page-filter-price-max-parent p
 {
  font-family: Mulish, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
}

.search-page-filter-price-min-parent input,
.search-page-filter-price-max-parent input{
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  user-select: none;
}

.search-page-filter-price-min-parent input:focus,
.search-page-filter-price-max-parent input:focus  {
  outline: none;
}


.search-page-filter-category-checkbox-parent {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.search-page-filter-category-checkbox-parent p {
  padding: 0;
  margin: 0;
  margin-left: 15px;
  
}

.search-page-body-right-posts-parent {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
}

.search-page-filter-parent-price-title {
  margin: 0;
  margin-top: 6px;
}


.search-filter-open-button {
  width: 100%;
  outline: 0;
  border: 0;
  margin: 0;
  margin-top: 10px;
  height: 40px;
  font-weight: bold;
  box-shadow: none;
  border-radius: 14px;
  font-size: 16px;
  padding: 6px 16px;
  cursor: pointer;
  color: #0a5174;
  border: 1px solid #032879;
  background-color: rgba(41, 163, 238, 0.14);
}

.search-page-loading-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

@media screen and (max-width: 900px) {

  .search-page-body {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 80vh;
  }
  .search-page-body-left {
    width: 100%;
  }
  
  .search-page-body-left-title {
    margin: 0;
    padding: 0;
    padding: 0 10px;
  }

  .search-page-body-right {
    width: 100%;
  }

  .search-page-body-right-posts-parent {
    margin: 0;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: center;
  }

  .search-page-filter-parent {
    border-radius: 16px;
    padding: 10px 13px;
    margin-top: 16px;
  }

  .search-page-loading-parent {
    margin: 0;
    margin-top: 20px;
  }
  
}


/* < post large item > */

.post-large-item-container {
  width: 260px;
  max-height: 380px; 
  margin: 10px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #F5F5F7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 20px;
}

.post-large-item-container:hover {
  box-shadow: 0 3px 6px rgba(39, 40, 44, 0.3);
  cursor: pointer;
}

.post-large-item-container img {
  width: 100%;
  max-height: 300px;
}

.post-large-item-text-parent {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.post-large-item-text-parent h2 {
  width: 90%;
}

.post-large-item-prices-parent {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.post-large-item-price {
  font-weight: bold;
  font-size: 18px;
  color: red;
  margin: 0;
}

.post-large-item-price-old {
  font-size: 16px;
  color: #BDBDBD;
  text-decoration: line-through;
  margin: 0;
  margin-left: 20px;
  text-align: center;
  align-items: center;
  align-self: center;
  text-align-last: center;
}

.post-large-item-name {
  margin: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 18px;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

@media screen and (max-width: 768px) {

  .post-large-item-container {
    width: 160px;
    max-height: 380px; 
    margin: 8px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #F5F5F7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .post-large-item-container:hover {
    box-shadow: 0 3px 6px rgba(39, 40, 44, 0.3);
    cursor: pointer;
  }

  .post-large-item-container img {
    width: 100%;
    max-height: 300px;
  }

  .post-large-item-text-parent {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .post-large-item-text-parent h2 {
    width: 90%;
  }

  .post-large-item-prices-parent {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .post-large-item-price {
    font-weight: bold;
    font-size: 15px;
    color: red;
    margin: 0;
  }

  .post-large-item-price-old {
    font-size: 14px;
    color: #BDBDBD;
    text-decoration: line-through;
    margin: 0;
    margin-left: 20px;
    text-align: center;
    align-items: center;
    align-self: center;
    text-align-last: center;
  }

  .post-large-item-name {
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
    max-lines: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
}


.basket-page-root {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: white;
}

.basket-page-body {
  margin: 0;
  padding: 0;
  padding-top: 80px;
  min-height: 80vh;
  background-color: #F5F5F7;
}

.basket-page-body h2{
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}
.basket-page-body h1{
  margin: 0;
  padding: 0;
  width: 90%;
  text-align: left;
  padding: 0 10px;
}

.basket-page-body-item-root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.basket-page-body-posts-left {
  width: 60%;
}
.basket-page-body-posts-parent {
  width: 100%;
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
}

.basket-page-body-posts-right {
  width: 35%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 10px 0;
  margin-right: 10px;
  margin-top: 10px;
  height: fit-content;
  position: sticky;
  z-index: 1;
  top:100px
}


.basket-page-contact-button-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.basket-page-contact-button {
  outline: 0;
  border: 0;
  margin: 0;
  width: calc(100% - 20px);
  height: 40px;
  font-weight: bold;
  box-shadow: none;
  border-radius: 14px;
  font-size: 16px;
  padding: 6px 16px;
  cursor: pointer;
  color: white;
  background-color: #0CD46C;
}

/* --------------- < BASKET ITEM > ----------------- */

.basket-item-container {
  width: 210px;
  height: 350px; 
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  background-color: #F5F5F7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.basket-item-container img {
  width: 100%;
}

.basket-item-text-parent {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 10px;
}

.basket-item-text-parent:hover {
  cursor: pointer;
}

.basket-item-text-parent-name {
  width: 90%;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-size: 18px;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basket-item-prices-parent {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 10px;
}

.basket-item-price {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  color: red;
}

.basket-item-price-old {
  padding: 0;
  margin: 0;
  font-size: 17px;
  margin-left: 15px;
  color: #BDBDBD;
  text-decoration: line-through;
}

.basket-item-button {
  width: 86%;
  margin: auto;
  outline: 0;
  border: 0;
  height: 30px;
  font-weight: bold;
  background-color: red;
  box-shadow: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  color: white;
}
.basket-item-container.grayed-out {
  filter: grayscale(100%);
  opacity: 0.5;
  pointer-events: none; /* Disable interactions */
}
.basket-item-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.basket-page-body-posts-right h4{
  margin: 0;
  padding: 0;
  margin-right: 20px;
}

.basket-page-body-right-text-line-1,
.basket-page-body-right-text-line-2,
.basket-page-body-right-text-line-3 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 20px 10px;
}


@media screen and (max-width: 800px) {
  .basket-page-body-item-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .basket-page-body-posts-left {
    width: 100%;
  }
  .basket-page-body-posts-parent {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
  }
  
  .basket-page-body-posts-right {
    margin: 0;
    width: 95%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 10px 0;
    margin: 10px;
    height: fit-content;
    top: 0;
  }
  

  .basket-item-container {
    width: 160px;
    height: 280px; 
    margin: 10px 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    background-color: #F5F5F7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .basket-item-price {
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    color: red;
  }
  
  .basket-item-price-old {
    padding: 0;
    margin: 0;
    font-size: 15px;
    margin-left: 15px;
    color: #BDBDBD;
    text-decoration: line-through;
  }

  .basket-item-text-parent-name {
    width: 90%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    max-lines: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  .basket-item-button {
    width: 86%;
    margin: 10px 5px;
    outline: 0;
    border: 0;
    height: 30px;
    font-weight: bold;
    background-color: red;
    box-shadow: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    color: white;
  }
}

/*    < price page >   */
.price-page-root {
  background: #f5f5f5;
  padding: 4rem 0;
}

.price-page-root-title {
  margin: 0;
  padding: 0;
  width: calc(100% - 80px);
  padding: 0 40px;
  font-weight: 700;
  font-size: 2rem;
  line-height: 120%;
}

.price-page-root-info {
  width: calc(100% - 80px);
  padding: 0 40px;
  font-size: 1.3rem;
  line-height: 120%;
}

.price-page-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  gap: 4rem;
  width: calc(100% - 120px);
  margin-top: 3rem;
  padding: 0 80px 40px 40px;
}

.price-page-device-selector {
  height: fit-content;
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 35%;
  max-width: 38%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  font-family: 'Times New Roman', Times, serif;
}


/* ------------- blue bg designs ---------  */
.right-bg-blur-blue { 
    position: absolute;
    transform: translate(-50%, -50%);
    top: 30%;
    left: 80%;
    width: 200px;
    height: 200px;
    -webkit-filter: blur(80px);
    filter: blur(90px);
    background: radial-gradient(circle at 50% 50%, rgba(76, 0, 255, 1), #4c00ff00);
    opacity: .7;
    z-index: 0;
}

.left-bg-blur-blue {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 90%;
    left: 20%;
    width: 200px;
    height: 200px;
    -webkit-filter: blur(80px);
    filter: blur(90px);
    background: radial-gradient(circle at 50% 50%, rgba(76, 0, 255, 1), #4c00ff00);
    opacity: .7;
    z-index: 0;
}


.price-page-device-type-container,
.price-page-product-type-container {
  margin-bottom: 20px;
  opacity: 0.9;

}

.price-page-device-type-container label,
.price-page-product-type-container label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
.price-page-device-type-container-seleter,
.price-page-complect-selecter {
  width: 100%;
  padding: 3px 7px;
  font-family: 'Times New Roman', Times, serif;
}

.price-page-device-type-container select,
.price-page-product-type-container select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 40px;
  font-size: 15px;
}


.price-page-input-number-container{
  margin-bottom: 20px;
  opacity: 0.9;
}

.price-page-input-number-root {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.price-page-input-number-container label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
.price-page-input-number-container select {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 40px;
  font-size: 15px;
}

.price-page-input-number-container-image {
  width: 40px;
  height: 38px;
  border: 1px  solid #4e4e4e;
  border-radius: 0px 8px 8px 0px;
  margin: 0;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.price-page-input-number-container-image img {
  width: 24px;
  cursor: pointer;
}

.price-page-number-input{
  margin: 0;
  padding: 0;
  width: calc(100% - 60px);
  border: 1px solid #4e4e4e;
  border-radius: 8px 0 0 8px;
  height: 32px;
  font-size: 15px;
  padding: 3px 10px;
}



.price-page-device-type-container option{
  color: #000;
  padding: 10px;
}
.product-dropdown {
  margin: 0;
  vertical-align: baseline;
  position: relative;
  overflow-y: auto;
  max-height: 180px;
  border-radius: 10px;
  padding: 0;
  padding: 0px 0 5px 10px;
  margin-top: 5px;
  border: 1px solid #9c9c9c;

}

.product-dropdown-mini{
  vertical-align: baseline;
  position: relative;
  overflow-y: auto;
  max-height: 100px;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  padding: 0px 0 5px 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
}


.price-page-evaluate-button {
  background: linear-gradient(90deg, blue, purple);
  border: none;
  color: white;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.price-page-evaluate-button:hover {
  opacity: 0.9;
}

.price-page-evaluate-button:disabled{
  cursor: not-allowed;
  opacity: 0.5;
}

.price-page-search-possible-product {
  margin: 0;
  padding: 0;
  width: calc(100% - 20px);
  border: 1px solid #4e4e4e;
  border-radius: 10px;
  height: 30px;
  font-size: 15px;
  padding: 3px 10px;
}
.price-page-search-possible-product-item {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 8px 0 0 0;
  color: black;
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
}

.price-page-search-possible-product-item:hover {
  margin: 0;
  padding: 0;
  margin: 8px 0 0 0;
  color: #4287f5;
}

.price-page-battery-input-root {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.price-page-battery-input-root-spanner {
  height: 36px;
}

.price-page-battery-input-root span{
  position: absolute;
  margin: 0;
  padding: 0;
  height: 20px;
  transition: transform 0.3s ease;
  transform: translateY(50%);
  right: 31px;
  cursor: pointer;
  font-size: small;
}

.price-page-search-notFound{
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.price-page-device-new {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.price-page-device-new span {
  font-weight: bold;
  text-align: left;
}

.price-page-device-new input{
  margin-right: 10px;
}

.price-page-link-doc-parent {
  margin-top: 15px;
}
.price-page-link-doc {
  margin-left: 10px;
}
.price-page-link-doc a {
  color: #4287f5;
}


.price-page-right {
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.price-page-right-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.price-page-right-container img {

  width: 70%;
  height:auto;
  object-fit: contain;
}

.price-page-right-container-title {    
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 120%;
}

.price-page-right-container-title span{
  color: rgb(24, 133, 242);

}

.price-page-right-container-info {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  width: 100%;
}


.product-value {
  width: calc(100% - 80px);
  padding: 40px;

}

.product-value-container {
  backdrop-filter: blur(20px);
  border-radius: 30px;
  padding: 20px 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: space-between;
  flex-direction: row;

}

.product-value-left{
  width: 45%;
}

.product-value-left p{
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}

.product-value-left h2{
  margin: 0;
  padding: 10px 0 30px 0;
}

.product-value-left h3{
  margin: 0;
  padding: 5px 0 30px 0;
}
.product-value-right{
  min-width: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.product-value-right h1 {
  width: 100%;
  text-align: center;
}

.product-value-right button{

  width: fit-content;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 60px;
  min-height: 64px;
  background: linear-gradient(218.13deg, rgb(56, 113, 220) 18.93%, rgb(0, 44, 129) 94.11%);
  border-radius: 12px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 120%;
  cursor: pointer;
}

.product-value-right p {
  text-align: center;
}

/* ---------- < Price Page Selector Package > ------------ */

.price-page-multi-select-dropdown {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative;
  font-family: 'Times New Roman', Times, serif;
}

.price-page-dropdown-header {
  padding: 10px;
  border: 1px solid #4e4e4e;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
}

.price-page-package-selected-option {
  background: #f1f1f1;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.price-page-package-remove-option {
  margin-left: 5px;
  cursor: pointer;
  color: red;
  display: flex;
}

.price-page-package-remove-option img{
  width: 15px;
  height: 100%;
  margin: auto;
}

.price-page-package-arrow {
  margin-left: auto;
  transition: transform 0.3s ease;
  font-size: small;
}

.price-page-package-dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: none;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.price-page-package-dropdown-container {
  margin-top: 10px;
  padding: 0;
  margin: 0;
  position: absolute;
  padding: 3px;
  width: calc(100% - 6px);
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 1000;
  background: #fff;

}

.price-page-package-dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-page-package-dropdown-item.selected {
  background: #c6f5fc;
}

.price-page-package-dropdown-item:hover {
  background: #fdfafa;
}

.price-page-package-dropdown-item.selected:hover {
  background: #c6f5fc;
}

.price-page-package-plus-icon {
  color: green;
  margin-right: 5px;
}

.price-page-package-check-icon {
  color: green;
}


.input-pc-character-container {
  position: relative;
  display: inline-block;
  width: 100%;
}


.price-page-input-clear {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px; 
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (max-width: 900px) {

  .price-page-root {
    padding: 4rem 0 1rem 0;
  }

  .price-page-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    gap: 4rem;
    width: calc(100% - 24px);
    margin-top: 2.5rem;
    padding: 0 12px;
  }
  
  .price-page-device-selector {
    height: fit-content;
    backdrop-filter: blur(20px);
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 90%;
    max-width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    overflow: hidden;
  }

  .price-page-root-title {
    margin: 0;
    padding: 0;
    width: calc(100% - 32px);
    padding: 0 16px;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 120%;
    
  }
  
  .price-page-root-info {
    width: calc(100% - 32px);
    padding: 0 16px;
    font-size: 0.975rem;
    line-height: 120%;
    font-weight: 450;
  }



  .price-page-right {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .price-page-right-container img {

    width: 90%;
    height:auto;
    object-fit: contain;
  }

  .price-page-right-container-title {    
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 120%;
  }
  .price-page-right-container-info {
    margin: 0;
    padding: 0;
    font-weight: 430;
    font-size: 1rem;
    line-height: 120%;
    width: 100%;
  }


  .product-value {
    width: 100%;
    padding: 0;
    display: block;
    margin: 0;
  }


  .product-value-container {
    margin: 0;
    backdrop-filter: blur(20px);
    border-radius: 30px;
    padding: 20px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.7);
  
  
    display: flex;
    justify-content: center;
    flex-direction: column;
  
  }

  .product-value-left{
    width: 90%;
    align-items: center;
    
  }
  
  .product-value-left p{
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }
  
  .product-value-left h2{
    font-size: 1.3rem;
    margin: 0;
    padding: 10px 0 20px 0;
  }
  .product-value-right{
    min-width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  
  .product-value-right h1 {
    width: 100%;
    text-align: center;
  }
  
  
  .product-value-right a{
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .product-value-right button{
    min-height: 0;
    height: 0;
    height: 56px;
    width: 100%;
    padding: 0;
    padding: 12px 20px;
    margin: 0;
  }
  
}


/*   --------- < Footer > -------------   */

.footer-container {
  text-align: center;
  padding: 10px 20px;
  background-color: #f1f1f1;
}
.footer-copyright{
  margin: 0;
}


.footer-social-links {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  
}

.footer-social-link {
  color: #000;
  text-decoration: none;
}

.footer-social-link-1:hover {
  color: red; 
}

.footer-social-link-2:hover {
  color: #f542da; 
}

.footer-social-link-3:hover {
  color: #007bff; 
}

.footer-contact {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-phone-number {
  font-size: 1em;
  margin-left: 10px;
}

.footer-copyright {
  margin-top: 20px;
  font-size: 0.9em;
  color: #666;
  user-select: none; /* Disable text selection */
  cursor: default; 
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.loading {
  align-self: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 6px solid turquoise;
  border-top: 6px solid transparent;
  animation: rotateLoading 1.5s infinite linear;
  z-index: 1;
}


.loading2 {
  align-self: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 6px solid rgb(56, 113, 220);
  border-top: 6px solid transparent;
  animation: rotateLoading 1.5s infinite linear;
}

.price-page-loading-parent {
  margin: 0;
  margin-top: 10px;

}

@keyframes rotateLoading {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

/* --------------- Doc Prices --------------- */

.doc-prices-root {
  width: 100%;
  padding: 0;
  margin: 0;
  padding-top: 60px;
  margin:0 15px;
}


/* --------------- Home Page Hello --------------- */

.hello-root{
  background-color: #F5F5F7;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  width: 100%;
}

.hello-parent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.hello-bg {
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hello-parent-title-parent {
  margin: 0 0 0 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hello-parent-title-parent h1 {
  margin: 0;
  font-size: 50px;
  cursor:default;
}

.hello-parent-title-parent h2{
  font-size: 40px;
  cursor:default;
}

.hello-parent-image-parent {
  width: 30%;
  margin: 0 100px 0 0;
}


.hello-parent-image-parent-img {
  height: 460px;
  width: 400px;

  margin: auto;
}


.hello-parent-link {
  padding: 10px 20px 12px 20px;
  
  border-radius: 10px;
  font-weight: bold;
  color: white;
  width: fit-content;
  align-items: center;
  text-align: center;
  background: linear-gradient(239.72deg, rgb(56, 113, 220) 0%, rgb(0, 44, 129) 102.7%);
}

.hello-parent-dayly-img {
  width: 40px;
  margin-bottom: 30px;
  margin-left: 10px;
}


.hello-parent-down-image-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.hello-parent-down-image {
  width: 50px;
}


.blue-shadow-left {    
  opacity: .5;
  position: absolute;
  width: 300px;
  height: 300px; 
  filter: blur(130px);
  background-color: #007bff;
  top: 380px;
  left:-15%;
}

.blue-shadow-right-parent {
  width: 100%;
  display: flex;
  justify-content: right;
  overflow: hidden;
}
.blue-shadow-right {
  opacity: .4;
  position: absolute;
  width: 200px;
  height: 200px; 
  filter: blur(100px);
  background-color: #007bff;
  top: 150px;
  right: 15%;
}

@media screen and (max-width: 1200px) {
  .hello-parent-title-parent h1 {
    margin: 0;
    font-size: 40px;
    cursor:default;
  }
  
  .hello-parent-title-parent h2{
    font-size: 30px;
    cursor:default;
  }

  .hello-parent-title-parent {
    margin: 0 0 0 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .hello-parent-image-parent {
    width: 40%;
    margin: 0 60px 0 0;
  }
  
}



/* ----------------- Price Hello Page ------------------ */

.price-hello-page-root {
  width: 100%;
  background: #f5f5f5;
}

.price-hello-page-bg {
  width: 100%;
  padding-top: 60px;
}


.price-hello-page-container {
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.price-hello-page-left {
  width: 45%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.price-hello-page-left-title {
  margin: 0;
}

.price-hello-page-left-title span{
  color: rgb(24, 133, 242);
}

.price-hello-page-left-title2 {
  font-size: 24px;
  margin-right: 60px;
}

.price-hello-page-left-button {
  width: fit-content;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 60px;
  min-height: 64px;
  background: linear-gradient(218.13deg, rgb(83, 102, 172) 18.93%, rgb(24, 133, 242) 94.11%);
  border-radius: 8px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 120%;
  cursor: pointer;
}

.price-hello-page-right {
  width: 45%;
  display: flex;
  justify-content: center;
}

.price-hello-page-right img{
  width: 100%;
  height:auto;
  object-fit: contain;
}


@media screen and (max-width: 900px) {


.price-hello-page-bg {
  width: 100%;
  padding: 0;
  padding-top: 60px;
}

  .price-hello-page-container {
    padding: 0;
    width: calc(100% - 36px);
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    padding: 0 18px;
  }

  .price-hello-page-left {
    width: 100%;
    min-height: 10vh;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin: 20px 0 40px 0;
  }


  .price-hello-page-left-title {
    margin: 0;
    font-size: 1.2rem;
  }


  .price-hello-page-left-title2 {
    font-size: 1rem;
    margin-right: 60px;
  }

  .price-hello-page-left-button {
    padding: 0;
    margin: 0;
    min-height: 10px;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    margin-top: 20px;
    padding: 1.1rem 30px;
    border-radius: 8px;
    border: none;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    line-height: 100%;
    cursor: pointer;
  }

  .price-hello-page-right {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }
  
  .price-hello-page-right img{
    width: 100%;
    height:auto;
    object-fit: contain;
  }
  
}


/* ------------- Price Steps Root ---------------- */

.price-step-root {
  width: 100%;
  background-color: #f5f5f5;
}

.price-step-bg{
  width: 100%;
}

.price-step-container {
  width: calc(100% - 80px);
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.5rem;
  height: 180px;
}

.price-steps-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px 24px;
  gap: 2px;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 12px;
}

.price-steps-item-top {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: left;
}

.price-steps-item-top span{
  font-weight: 500;
  font-size: 80px;
  line-height: 120%;
  margin-right: 1.5rem;
  background: linear-gradient(218.13deg, rgb(83, 102, 172) 18.93%, rgb(24, 133, 242) 94.11%) text;
  -webkit-text-fill-color: transparent;
}

.price-steps-item-top p {
  font-weight: bold;
  color: black;
  font-size: 1.2rem;
}

.price-steps-item-bottom p {
  color: black;
  font-size: 1rem;
}

.price-steps-item-bottom span{
  background: linear-gradient(218.13deg, rgb(83, 102, 172) 18.93%, rgb(24, 133, 242) 94.11%) text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .price-step-container {
    height: 240px;
  }
}

@media screen and (max-width: 900px) {
  .price-step-container {
    width: calc(100% - 24px);
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    gap: 1rem;
  }

  .price-steps-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px 18px;
    gap: 0;
    width: calc(100% - 36px);
    height: 100%;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border-radius: 12px;
  }


  .price-steps-item-top span{
    font-weight: 500;
    font-size: 60px;
    line-height: 120%;
    margin-right: 1.3rem;
  }


  .price-steps-item-top p {
    font-weight: bold;
    color: black;
    font-size: 1.06rem;
  }


  .price-steps-item-bottom p {
    color: black;
    font-size: 0.92rem;
  }

}


/*      -     < Admin Prices Page >      -      */
.prices-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1px;
  background-color: #ccc;
}

.grid-root {
  padding-top: 40px;
}

.prices-grid-three {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  background-color: #ccc;
}

.prices-grid-one {
  width: 64%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1px;
  background-color: #ccc;
}

.priced-requests-grid-parent {
  display: flex;
  flex-direction: row;
}

.prices-row {
  display: contents;
}

.prices-row.header {
  font-weight: bold;
  background-color: #f0f0f0;
}

.prices-cell {
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.prices-cell-mini{
  padding: 6px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: left;
  font-size:small;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



.social-link-root {
  position: fixed;
  right: 20px;
  bottom: 20px;
  user-select: none;
  z-index: 2147483646;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  justify-self: end;
  margin: 5px 10px;
}

.social-link-root img {
  width: 50px;
  height: 50px;
  margin-top: 9px;
  cursor: pointer;
}


@media screen and (max-width: 700px) {
  .social-link-root {
    right: 10px;
  }
}
.call-number-input-panel-root {
  position: fixed;
  z-index: 999;
  background-color: rgba(33, 33, 33, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-number-input-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: rgb(206, 206, 206);
  padding: 0px;
  max-height: 100%;
  width: 50%;
  height: 60%;
  border-radius: 12px;
  overflow: hidden;
}

.close-image-root {
  height: 65%;
  filter: invert(1) brightness(2);
}
.close-image-root img{
  width: 23px;
  height: 23px;
  margin-left: 10px;
  cursor: pointer;
}

.call-number-left {
  width: 50%;
  height: 100%;
}

.call-number-left img {
  width: 100%;
  height: 100%;
  opacity: .8;
  filter: saturate(150%);
  object-fit: cover;
}

.call-number-right {
  width: 50%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}


.call-number-right-root {
  width: calc(100% - 40px);
  padding: 30px 20px;
  max-height: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.call-number-right-root-title {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: large;
  color: #000;
}

.call-number-right-root-info {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size:medium;
  color: #a29e9e;
}

.call-number-right-root input {
  width: calc(100% - 40px);
  height: 40px;
  border-radius: 10px;
  padding: 8px 20px;
  border: 1px solid #ccc;
  font-size: medium;
}

.call-number-right-root button{
  background: linear-gradient(90deg, rgb(23, 23, 247), rgb(2, 99, 151));
  border: none;
  color: white;
  padding: 15px;
  border-radius: 9px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.call-number-right-root button:disabled{
  opacity: .6;
}

.navbar-admin-links{
  width: fit-content;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  border: 1px  solid rgb(192, 190, 190);
  z-index: 100;
}

.admin-links-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 30px;
  width: 100%;
}

.admin-links-container a{
  font-weight: 600;
  color: blue;
}


@media screen and (max-width: 900px) {
  .admin-links-container a{
      font-size: 12px;
  }
  .admin-links-container {
    margin: 10px 10px;
  }
}
@media screen and (max-width: 700px) {
  .admin-links-container a{
      font-size: 10px;
  }
  .admin-links-container {
    margin: 10px 10px;
  }
}

.full-len-parent {
  min-width: 1450px;
}



/* --------------- < Login Page > ------------------ */
.login-page-root{
  width: 100%;
  background-color: #f5f5f5;
}

.login-page-container {
  background-color: #f5f5f5;
  padding-top: 80px;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.login-page-container-title {
  margin-top: 40px;
}

.login-page-panel {
  margin-top: 5px;
  width: 600px;
  min-height: fit-content;
  background-color: white;
  border: 1px  solid rgb(255, 255, 255);
  border-radius: 18px 18px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-page-number-input-container,
.login-page-password-input-container {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 22px;
}

.login-page-number-input-container label,
  .login-page-password-input-container label{
  font-size: smaller;
  font-weight: 600;
}

.login-page-number-input-container input ,
  .login-page-password-input-container input {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  padding: 4px 8px;
  border: 1px  solid #4e4e4e;
  border-radius: 8px;
  height: 30px;
  font-size: medium;
  outline: none;
}

.login-page-password-input-root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.login-page-password-input-container input {
  width: 100%;
  border-radius: 8px 0px 0px 8px;
  margin: 0;
}

.login-page-password-input-container input::-moz-selection{
  border: 1px  solid #4e4e4e;
}

.login-page-password-hiden-image {
  width: 40px;
  height: 38px;
  border: 1px  solid #4e4e4e;
  border-radius: 0px 8px 8px 0px;
  margin: 0;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.login-page-password-hiden-image img {
  width: 24px;
  cursor: pointer;
}

.login-page-login-button {
  width: calc(100% - 40px);
  font-weight: bold;
  margin: 40px 20px 0 20px;
  padding: 12px 60px;
  min-height: 50px;
  background: linear-gradient(218.13deg, rgb(83, 102, 172) 18.93%, rgb(24, 133, 242) 94.11%);
  border-radius: 8px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 120%;
  cursor: pointer;
}

.login-page-login-button:disabled{
  cursor: not-allowed;
  opacity: 0.6;
}

.login-page-link-forgot-password {
  text-align: center;
  width: 100%;
  margin: 0;
  margin: 10px 0 25px 0;
  font-size: 15px;
  color:#007bff;
  text-decoration: underline;
  cursor: pointer;
}

.login-page-link-to-register-panel {
  margin-top: 10px;
  width: 600px;
  min-height: fit-content;
  background-color: white;
  border: 1px  solid rgb(255, 255, 255);
  border-radius: 0 0 18px 18px ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-page-link-to-register-button {
  width: calc(100% - 40px);
  font-weight: bold;
  margin: 10px 20px 10px 20px;
  padding: 12px 60px;
  min-height: 50px;
  border-radius: 8px;
  border: 1px  solid rgb(29, 119, 247);
  background-color: white;
  color: black;
  font-size: 1rem;
  line-height: 120%;
  cursor: pointer;
}

.login-page-login-error {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 900px) {

  .login-page-container {
    min-height: 70vh;
  }
  .login-page-panel {
    width: 90%;
  }

  .login-page-link-to-register-panel {
    width: 90%;
  }

}





/* --------------- < Register Page > ------------------ */
.register-page-root{
  width: 100%;
  background-color: #f5f5f5;
}

.register-page-container {
  background-color: #f5f5f5;
  padding-top: 80px;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.register-page-container-title {
  margin-top: 40px;
}

.register-page-panel {
  margin-top: 5px;
  width: 600px;
  min-height: fit-content;
  background-color: white;
  border: 1px  solid rgb(255, 255, 255);
  border-radius: 18px 18px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.register-page-number-input-container,
.register-page-password-input-container,
  .register-page-fullname-input-container,
    .register-page-email-input-container {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 22px;
}

.register-page-number-input-container label,
  .register-page-password-input-container label,
    .register-page-fullname-input-container label,
      .register-page-email-input-container label{
  font-size: smaller;
  font-weight: 600;
}

.register-page-number-input-container input ,
  .register-page-password-input-container input,
    .register-page-fullname-input-container input,
      .register-page-email-input-container input {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  padding: 4px 8px;
  border: 1px  solid #4e4e4e;
  border-radius: 8px;
  height: 30px;
  font-size: medium;
  outline: none;
}

.register-page-password-input-root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.register-page-password-input-container input {
  width: 100%;
  border-radius: 8px 0px 0px 8px;
  margin: 0;
}

.register-page-password-input-container input::-moz-selection{
  border: 1px  solid #4e4e4e;
}

.register-page-password-hiden-image {
  width: 40px;
  height: 38px;
  border: 1px  solid #4e4e4e;
  border-radius: 0px 8px 8px 0px;
  margin: 0;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.register-page-password-hiden-image img {
  width: 24px;
  cursor: pointer;
}

.register-page-register-button {
  width: calc(100% - 40px);
  font-weight: bold;
  margin: 40px 20px 20px 20px;
  padding: 12px 60px;
  min-height: 50px;
  background: linear-gradient(218.13deg, rgb(83, 102, 172) 18.93%, rgb(24, 133, 242) 94.11%);
  border-radius: 8px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 120%;
  cursor: pointer;
}

.register-page-register-button:disabled{
  cursor: not-allowed;
  opacity: 0.6;
}


.register-page-link-to-login-panel {
  margin-top: 10px;
  width: 600px;
  min-height: fit-content;
  background-color: white;
  border: 1px  solid rgb(255, 255, 255);
  border-radius: 0 0 18px 18px ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.register-page-link-to-login-button {
  width: calc(100% - 40px);
  font-weight: bold;
  margin: 10px 20px 10px 20px;
  padding: 12px 60px;
  min-height: 50px;
  border-radius: 8px;
  border: 1px  solid rgb(29, 119, 247);
  background-color: white;
  color: black;
  font-size: 1rem;
  line-height: 120%;
  cursor: pointer;
}

.register-page-verify-number-container {
  width: calc(100% - 40px);
  padding: 0 20px;
  margin-top: 20px;
}

.register-page-verify-number-container button {
  min-width: 40%;
  padding: 4px 20px;
  height: 30px;
  color: #007bff;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px  solid rgb(29, 119, 247);
  cursor: pointer;
}

@media screen and (max-width: 900px) {

  .register-page-container {
    min-height: 70vh;
  }
  .register-page-panel {
    width: 90%;
  }

  .register-page-link-to-login-panel {
    width: 90%;
  }

}



.redirect-product-page-root {
  width: 100%;
}
.redirect-product-page-root h3 {
  margin-left: 10px;
}

.redirect-app-download-root {
  width: 100%;
}
.redirect-app-download-background {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.redirect-app-download-background img{
  width: 200px;
}

.redirect-app-download-background button{
  width: fit-content;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 60px;
  min-height: 64px;
  background: linear-gradient(218.13deg, rgb(83, 102, 172) 18.93%, rgb(24, 133, 242) 94.11%);
  border-radius: 8px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 120%;
  cursor: pointer;
}








/* --------------------------                          */
.aliexpress-ads-root {
  background-color: #F5F5F7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.aliexpress-ads-root img {
  max-width: 100%;
  height: auto;
}

.ad-maneger {
  width: 100%;
  background-color: #F5F5F7;
}
.yandex_rtb_R-A-14540537-1{
  margin: 0 5% 0 5%;
  width: 90%;
  height: auto;
  min-height: 300px;
  background-color: #F5F5F7;
}